import React from "react"
import { detectMob } from "@src/Helper"

const ResetPasswordInputPassword = ({
  newPasswordRef,
  newEyeRef,
  newPassword,
  isInputEmpty,
  isPasswordVisible,
  error,
}) => {
  if (detectMob()) {
    return (
      <div className="reset-password__form__password">
        <label
          htmlFor="inputNewPassword"
          className="reset-password__form__password__label label-sm"
        >
          Kata Sandi Baru
        </label>
        <div className="position-relative">
          <input
            type="password"
            className="reset-password__form__password__input input-md"
            id="inputNewPassword"
            spellCheck={false}
            placeholder="Masukkan minimal 6 karakter"
            ref={newPasswordRef}
            onChange={e => {
              isInputEmpty(newPasswordRef)
              newPassword.setValue(e.target.value)
            }}
          />
          <span
            role="button"
            aria-label="Show Password"
            tabIndex="0"
            className="reset-password__form__password__show mdi mdi-eye-off"
            ref={newEyeRef}
            onClick={() => isPasswordVisible(newPasswordRef, newEyeRef)}
            onKeyDown={e => {
              if (e.key === "Enter")
                isPasswordVisible(newPasswordRef, newEyeRef)
            }}
          ></span>
        </div>
        {error.value === "" ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value}</span>
        )}
      </div>
    )
  } else {
    return (
      <div className="reset-password__form__password">
        <label
          htmlFor="inputNewPassword"
          className="reset-password__form__password__label label-sm"
        >
          Kata Sandi Baru
        </label>
        <div className="position-relative">
          <input
            type="password"
            className="reset-password__form__password__input input-md"
            id="inputNewPassword"
            spellCheck={false}
            placeholder="Masukkan minimal 6 karakter"
            ref={newPasswordRef}
            onChange={e => {
              isInputEmpty(newPasswordRef)
              newPassword.setValue(e.target.value)
            }}
          />
          <span
            role="button"
            aria-label="Show Password"
            tabIndex="0"
            className="reset-password__form__password__show mdi mdi-eye-off"
            ref={newEyeRef}
            onClick={() => isPasswordVisible(newPasswordRef, newEyeRef)}
            onKeyDown={e => {
              if (e.key === "Enter")
                isPasswordVisible(newPasswordRef, newEyeRef)
            }}
          ></span>
        </div>
        {error.value === "" ? (
          <></>
        ) : (
          <span className="error-message caption">{error.value}</span>
        )}
      </div>
    )
  }
}

export default ResetPasswordInputPassword
