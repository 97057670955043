import React from "react"
import { detectMob } from "@src/Helper"

const ResetPasswordInputConfirmation = ({
  confirmPasswordRef,
  confirmEyeRef,
  confirmPassword,
  isInputEmpty,
  isPasswordVisible,
}) => {
  if (detectMob()) {
    return (
      <div className="reset-password__form__confirm-password">
        <label
          htmlFor="inputConfirmPassword"
          className="reset-password__form__confirm-password__label label-sm"
        >
          Konfirmasi Kata Sandi Baru
        </label>
        <div className="position-relative">
          <input
            type="password"
            className="reset-password__form__confirm-password__input input-md"
            id="inputConfirmPassword"
            spellCheck={false}
            placeholder="Ketik ulang kata sandi baru"
            ref={confirmPasswordRef}
            onChange={e => {
              isInputEmpty(confirmPasswordRef)
              confirmPassword.setValue(e.target.value)
            }}
          />
          <span
            role="button"
            aria-label="Show Password"
            tabIndex="0"
            className="reset-password__form__confirm-password__show mdi mdi-eye-off"
            ref={confirmEyeRef}
            onClick={() => isPasswordVisible(confirmPasswordRef, confirmEyeRef)}
            onKeyDown={e => {
              if (e.key === "Enter")
                isPasswordVisible(confirmPasswordRef, confirmEyeRef)
            }}
          ></span>
        </div>
      </div>
    )
  } else {
    return (
      <div className="reset-password__form__confirm-password">
        <label
          htmlFor="inputConfirmPassword"
          className="reset-password__form__confirm-password__label label-sm"
        >
          Konfirmasi kata sandi baru
        </label>
        <div className="position-relative">
          <input
            type="password"
            className="reset-password__form__confirm-password__input input-md"
            id="inputConfirmPassword"
            spellCheck={false}
            placeholder="Ketik ulang kata sandi baru"
            ref={confirmPasswordRef}
            onChange={e => {
              isInputEmpty(confirmPasswordRef)
              confirmPassword.setValue(e.target.value)
            }}
          />
          <span
            role="button"
            aria-label="Show Password"
            tabIndex="0"
            className="reset-password__form__confirm-password__show mdi mdi-eye-off"
            ref={confirmEyeRef}
            onClick={() => isPasswordVisible(confirmPasswordRef, confirmEyeRef)}
            onKeyDown={e => {
              if (e.key === "Enter")
                isPasswordVisible(confirmPasswordRef, confirmEyeRef)
            }}
          ></span>
        </div>
      </div>
    )
  }
}

export default ResetPasswordInputConfirmation
