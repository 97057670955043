import * as React from "react"
import ResetPassword from "@components/Auth/ResetPassword/ResetPassword"
import Seo from "@components/seo"

const ResetPasswordPage = ({ location }) => (
  <>
    <Seo title="Reset Password" />
    <ResetPassword location={location} />
  </>
)

export default ResetPasswordPage
