import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import qs from "query-string"
import { resetPassword } from "@http/AuthRepository"
import {
  detectMob,
  useInput,
  isInputEmpty,
  isPasswordVisible,
  isEmptyObject,
} from "@src/Helper"
import ResetPasswordInputConfirmation from "./ResetPasswordInputConfirmation"
import ResetPasswordInputPassword from "./ResetPasswordInputPassword"

const ResetPassword = ({ location }) => {
  // Hooks
  const newPasswordRef = useRef(null)
  const confirmPasswordRef = useRef(null)
  const newEyeRef = useRef(null)
  const confirmEyeRef = useRef(null)
  const newPassword = useInput("")
  const confirmPassword = useInput("")
  const queryString = useInput("")
  const error = useInput("")
  const isLoading = useInput(false)
  const isResetSuccess = useInput(false)

  useEffect(() => {
    queryString.setValue(qs.parse(location.search))
  }, [])

  // Custom Functions
  const handleResetPassword = token => {
    error.setValue("")
    isLoading.setValue(true)
    resetPassword(newPassword.value, confirmPassword.value, token)
      .then(() => {
        isResetSuccess.setValue(true)
        isLoading.setValue(false)
        error.setValue("")
      })
      .catch(err => {
        if (err.response.data.status_code === 422) {
          error.setValue(err.response.data.errors.password[0])
        }
        isLoading.setValue(false)
        isResetSuccess.setValue(false)
      })
  }

  // Custom Component
  const ResetSuccess = () => {
    if (detectMob()) {
      return (
        <div id="m-resetSuccess" className="reset-success">
          <div className="container">
            <div className="row">
              <div className="flex-col col-12">
                <StaticImage
                  className="reset-success__icon"
                  src="../../../images/success-icon.svg"
                  alt="Link Sent"
                  width={58}
                  height={58}
                />
                <h1 className="reset-success__title heading-3">Berhasil</h1>
                <p className="reset-success__description body">
                  Kata sandi baru berhasil diterapkan. Silahkan masuk kembali
                  dengan kata sandi baru.
                </p>
                <Link
                  className="reset-success__button button button-md button-primary"
                  to="/login"
                >
                  Masuk Sekarang
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="reset-success">
          <div className="container pad-t-150">
            <div className="row">
              <div className="flex-col col-6 offset-3">
                <StaticImage
                  className="reset-success__icon"
                  src="../../../images/success-icon.svg"
                  alt="Link Sent"
                  width={73}
                  height={73}
                />
                <h1 className="reset-success__title heading-3">Berhasil</h1>
                <p className="reset-success__description body">
                  Kata sandi baru berhasil diterapkan. Silahkan masuk kembali
                  dengan kata sandi baru.
                </p>
                <Link
                  className="reset-success__button button button-md button-primary"
                  to="/login"
                >
                  Masuk Sekarang
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  // JSX
  if (detectMob()) {
    return isResetSuccess.value ? (
      <ResetSuccess />
    ) : (
      <div id="m-resetPassword" className="reset-password">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="reset-password__title heading-3">
                Ubah Kata Sandi
              </h1>
              <p className="reset-password__description body">
                Masukkan kata sandi baru dan konfirmasi.
              </p>
              <form
                className="reset-password__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleResetPassword(queryString.value.token)
                }}
              >
                <ResetPasswordInputPassword
                  newPasswordRef={newPasswordRef}
                  newEyeRef={newEyeRef}
                  newPassword={newPassword}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  isPasswordVisible={isPasswordVisible}
                  error={error}
                />
                <ResetPasswordInputConfirmation
                  confirmPasswordRef={confirmPasswordRef}
                  confirmEyeRef={confirmEyeRef}
                  confirmPassword={confirmPassword}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  isPasswordVisible={isPasswordVisible}
                />
                <button
                  type="submit"
                  className="reset-password__form__button button button-md button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Ganti Password</>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return isResetSuccess.value ? (
      <ResetSuccess />
    ) : (
      <div className="reset-password">
        <div className="container pad-t-175">
          <div className="row">
            <div className="col-6 offset-3">
              <h1 className="reset-password__title heading-3">
                Ubah Kata Sandi
              </h1>
              <p className="reset-password__description body">
                Masukkan kata sandi baru dan konfirmasi.
              </p>
              <form
                className="reset-password__form"
                onSubmit={e => {
                  e.preventDefault()
                  handleResetPassword(queryString.value.token)
                }}
              >
                <ResetPasswordInputPassword
                  newPasswordRef={newPasswordRef}
                  newEyeRef={newEyeRef}
                  newPassword={newPassword}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  isPasswordVisible={isPasswordVisible}
                  error={error}
                />
                <ResetPasswordInputConfirmation
                  confirmPasswordRef={confirmPasswordRef}
                  confirmEyeRef={confirmEyeRef}
                  confirmPassword={confirmPassword}
                  isInputEmpty={isInputEmpty}
                  isEmptyObject={isEmptyObject}
                  isPasswordVisible={isPasswordVisible}
                />
                <button
                  type="submit"
                  className="reset-password__form__button button button-md button-primary"
                  disabled={isLoading.value}
                >
                  {isLoading.value ? (
                    <span
                      className="spinner-border spinner-border-sm ms-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <>Ganti Password</>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword
